<template>
  <section class="hero">
    <div class="above-the-fold app-width">
      <a
        v-if="false"
        href="https://chat.openai.com/g/g-jr9TOyaxH-youchat"
        target="_blank"
      >
        <div class="feature">
          <div class="feature-icon">
            <span class="tag ai">ChatGPT</span>
          </div>
          <div class="content">
            <strong>
              Now works with ChatGPT! Find us in the GPT store.
            </strong>
            <p>
              Use YouChat directly in ChatGPT with our new integration.
            </p>
          </div>
          <div class="arrow-container">
            <IconSvg name="chevron-arrow" />
          </div>
        </div>
      </a>
      <div />
      <div
        style="max-width: 1000px; margin: 0 auto;"
      >
        <TheStoryFolderLogo style="font-size: 2em" />
        <br><br>
        <p>
          🤖 A free GPT for ChatGPT users<img
            src="/img/powered-by-openai-badge-outlined-on-light.svg"
            width="150"
            style="vertical-align: middle; margin-left: 0.5em;"
          >
        </p>
        <h1
          class="
            main"
        >
          Chat with over 700M+ YouTube Videos
        </h1>
        <h3>
          Ask questions, highlight key points, explain, summarize, translate, transform, and more.
        </h3>


        <h3 v-if="false">
          Chat with YouTube Videos - Ask questions, highlight key points, explain, summarize, translate, expand upon, and much more.
        </h3>
        <div
          v-if="false"
          class="search-form"
        >
          <input
            type="text"
            placeholder="Paste a YouTube link here"
            class="search"
            @input="url = $event.target.value"
            @keyup.enter="search"
          >
          <Button
            class="primary"
            label="Chat"
            :has-chevron="true"
            target="_blank"
            @click="search"
          />
        </div>
        <div v-else>
          <Button
            class="primary"
            label="Use with ChatGPT Now"
            :has-chevron="true"
            to="https://chat.openai.com/g/g-jr9TOyaxH-youchat"
          />
        </div>
        <HorizontalRule class="dark" />
        <h4>Get updates</h4>
        <div
          v-if="!subscribed"
          class="search-form"
        >
          <input
            v-model="email"
            type="text"
            placeholder="Your email"
            class="search"
            @keyup.enter="getUpdated"
          >
          <Button
            label="Get updates"
            icon="envelope"
            target="_blank"
            @click="getUpdated"
          />
        </div>
        <div v-else>
          <IconSvg name="check" /> You will be updated when new features are added.
        </div>
        <br>
        <div>Need help? Email me. jeff@jeffjassky.com.</div>
      </div>
      <div />
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import IconSvg from "@/components/atoms/IconSvg.vue";
  import Button from "@/components/atoms/Button.vue";
  import HorizontalRule from "@/components/atoms/HorizontalRule.vue";
  import TheStoryFolderLogo from "@/components/molecules/TheStoryFolderLogo.vue";

  export default Vue.extend({
    components: {
      TheStoryFolderLogo,
      HorizontalRule,
      Button,
      IconSvg
    },
    props: {

    },
    data(){
      return {
        url: '',
        email: '',
        subscribed: false
      }
    },
    computed: {
      youtubeVideoId() {
        const youtubeUrlRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;

        // Ensure that this is a valid YouTube link
        const url = this.url;
        if (url && youtubeUrlRegex.test(url)) {
          const url = new URL(this.url);
          const params = new URLSearchParams(url.search);
          let videoId = params.get('v');
          if(!videoId){
            // it should be the pathname
            const pathname = url.pathname;
            const parts = pathname.split('/');
            videoId = parts[parts.length - 1];
          }
          return videoId;
        }
        return null;
      }
    },
    watch: {
      youtubeVideoId() {
        if(this.youtubeVideoId) {
          this.$router.push({
            name: 'chat',
            query: {
              v: this.youtubeVideoId
            }
          });
        }
      }
    },
    methods: {
      search(){
        if(this.url){
          console.log(this.url);
        }
      },
      async getUpdated(){
        if(this.email){
          try{
            await this.$store.dispatch('user/stayUpdated', {email: this.email})
            this.subscribed=true;
            this.email = '';
          } catch(e){
            console.error('Couldnt be updated', e)
            console.log(e.response.body)
          }
        }
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';

  p, h1, h3{
    color: black !important;
  }
  input, button, a{
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  a.primary{
    background: teal !important;
    border-radius: 0.5em !important;
  }
  section {
    overflow: hidden;
    border-bottom: 0;
    min-height: 100vh;
    background: white;
    display: flex;
    align-items: stretch;
  }
  .above-the-fold{
    z-index: 1;
    padding-top: 70px;
    padding-bottom: 8vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .search-form{
    display: flex;
    gap: 0.5em;
    align-items: stretch;
    justify-content: stretch;
    input, button{
      box-shadow: 0px 0px 35px fade(@black, 20%);
    }
  }
  input{
    border:2px solid @black;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    width: 100%;
    font: inherit;
    font-size: 1.25em;
  }
  button{
    height: auto;
    margin: 0;
  }
  .side-by-side{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 2rem;
    text-align: center;
    margin-top: 3em;
  }
  h1{
    margin-top: 0;
    line-height: 1.1em;
    padding-bottom: 0.8em;
    font-size: 1.9rem;
    overflow: visible;
    div.gradient{
      white-space: nowrap;
    }
  }
  ol{
    font-size: 1.1em;
    margin-bottom: 2em;
    list-style: none;
    counter-reset: li;
    padding-left: 1.5em;
    display: inline-block;
    li{
      margin-bottom:0.5em;
      color: white;
      font-weight: 500;
      padding-left: 1.25em;
      &:before{
        content: counter(li) + '.';
        color: @grayMedium;
        display: inline-block;
        width: 1em;
        margin-left:-1.25em;
        counter-increment: li;
      }
    }
  }
  p{
      margin:0 auto 1em auto;
      line-height: 1.5em;
      &.first{
        font-size: 1.25rem;
        color: white;
        font-weight: 500;
      }
  }

  .feature{
    background-color: #49b3b6;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 1em;
    border-radius: 0.5em;
    font-size: 0.9rem;
    display: flex;
    gap: 1em;
    margin-bottom: 2em;
    color: white;
    &.special{
      background-image: url(/img/backgrounds/prism-background.jpg);
      span.tag.ai{
        box-shadow: 0px 0px 35px fade(@black, 50%);
      }
    }
    p{
      font-size: inherit;
      margin: 0;
      color: @white;
    }
    .feature-icon{
      display: none;
    }
    span.tag.ai{
      background: @white;
      font-weight: 600;
      color: #49b3b6;
      padding: 0.25em 0.5em;
      border-radius: 0.25em;
      font-size: 24px;
    }
    .arrow-container{
      display: flex;
      align-items: center;
    }
  }

  .hero-image{
    width: 100%;
  }
  .gray{
    color: @grayMedium;
  }

  .os-list{
    margin: 1em auto 0 auto;
    font-size: 80%;
    .icon{
      zoom: 1.3;
    }
    span{
      white-space: nowrap;
    }
  }

  .video-container {
    position: relative;
    button{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15em;
      margin-top: -2em;
      margin-left: -7.5em;
      background: rgba(0,0,0,0.7);
      transition: none;
      &:hover{
        opacity: 1;
        background: rgba(0,0,0,0.9);
      }
    }
  }
  video{
    border-radius: 1rem;
    width: 100%;
    max-width: 1200px;
    cursor: pointer;
    aspect-ratio: 1.655/1;
  }

  @media(min-width: @zeroethbreakpoint){
    h1{
      padding-bottom: 0.3em;
      font-size: 2.5rem;
    }
  }

  @media(min-width: @firstbreakpoint){
    button{
      width: auto;
      margin: 0;
      display: inline-block;
    }
    .hero-image{
      width: 90%;
    }
    h1{
      font-size: 2.75rem;
    }
    h3{
      font-size: 1.5rem;
    }
    .feature{
      grid-template-columns: 80px 1fr 50px;

      .feature-icon{
        display: block;
      }
    }
  }
  @media(min-width: @secondbreakpoint){
    h1{
      font-size: 3.5rem;
    }
    ul{
        font-size: 20px;
    }
  }
  @media(min-width: @thirdbreakpoint){
    ul{
        margin-bottom: 0;
    }
    p, p.first{
        font-size: 1.25em;
    }
    .above-the-fold{
      padding-top: 100px;
    }
    h3{
      font-size: 1.75rem;
    }
  }
  @media(min-width: @fourthbreakpoint){
    h1{
      font-size: 3.5rem;
    }
    .side-by-side{
      grid-template-columns: 55% 1fr;
      text-align: left;
      justify-content: center;
      align-items: flex-start;
    }
  }
  @media(min-width: @fifthbreakpoint){
    h1{
      font-size: 4rem;
    }
  }
</style>
