<template>
  <section>
    <div class="app-width">
      <h1>Get More from YouTube</h1>
      <h3 class="center">
        Transform How You Watch, Learn, and Engage with Videos.
      </h3>
    </div>
    <div class="coverflow">
      <img
        v-if="false"
        name="features"
        src="/img/storyfolder-desktop-windows.png"
        loading="lazy"
      >
    </div>
    <div class="app-width">
      <div class="featurettes">
        <Featurette
          icon="graduation-cap"
          title="Learn and Educate Faster"
          description="Chat with videos to learn and develop skills for personal or professional growth."
        />
        <Featurette
          icon="gear"
          title="Boost Productivity"
          description="Instantly streamline tasks, gather insights, and improve efficiency in your workflow."
        />
        <Featurette
          icon="play"
          title="Enhanced Creativity"
          description="Turning videos into compelling insights for writing, marketing, and content creation."
        />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Featurette from "@/components/molecules/Featurette.vue";

  export default Vue.extend({
    components: {
      Featurette
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section{
    background: #eee;
  }
  .app-width{
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  h1{
    margin: 1.25em 0 0.5em 0;
    text-align: center;
  }
  .featurettes{
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    margin-top: 1em;
    margin-bottom: 3em;
    gap: 1em;
  }
  .featurette{
    margin-bottom: 1.5em;
  }
  h3{
    margin-bottom: 1em;
    text-align: center;
  }

  .coverflow{
    text-align: center;
    margin: 2em 0;
    img{
      width: 80%;
    }
  }
  @media(min-width: @firstbreakpoint){
    .coverflow img{
      width: 80%;
    }
  }
  @media(min-width: @thirdbreakpoint){
    .featurette{
      font-size: 1.5rem;
    }
    .featurettes{
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 7em;
    }
    h1 span, h3 span{
      display: block;
    }
  }
</style>
