<template>
  <section>
    <div class="app-width">
      <div class="os">
        <h3><IconSvg name="apple" /> macOS</h3>
        <div class="table">
          <div class="tr">
            <div class="td title">
              Mac Model
            </div>
            <div class="td description">
              MacBook, MacBook Air, MacBook Pro, iMac, iMac Pro, Mac Pro, Mac mini, early 2010 or newer
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              Processor
            </div>
            <div class="td description">
              CPU Intel® Core™ i5 or better
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              OS Version
            </div>
            <div class="td description">
              macOS 10.13.6 or higher.
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              RAM
            </div>
            <div class="td description">
              Memory 8 GB RAM or more (16+ GB RAM is recommended)
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              Disk Space
            </div>
            <div class="td description">
              Hard disk 5 GB free space; SSD for best performance
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              Display
            </div>
            <div class="td description">
              1280x768 size or better
            </div>
          </div>
        </div>
      </div>
      <div class="os">
        <h3><IconSvg name="windows" /> Windows</h3>
        <div class="table">
          <div class="tr">
            <div class="td title">
              Hardware
            </div>
            <div class="td description">
              Windows-based hardware PC with mouse or similar input device
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              Processor
            </div>
            <div class="td description">
              CPU Intel® Core™ i5 or better, AMD Ryzen™ 5 or better
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              OS Version
            </div>
            <div class="td description">
              10 (only 64-bit OS).
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              RAM
            </div>
            <div class="td description">
              Memory 8 GB RAM or more (16+ GB RAM is recommended)
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              Disk Space
            </div>
            <div class="td description">
              Hard disk 5 GB free space; SSD for best performance
            </div>
          </div>
          <div class="tr">
            <div class="td title">
              Display
            </div>
            <div class="td description">
              1280x768 size or better
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import IconSvg from "@/components/atoms/IconSvg.vue";
  export default Vue.extend({
    components: {
      IconSvg
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section{
    background: black;
  }
  .os{
    padding: 2em 0;
    &:nth-child(2){
      border-top: 1px solid fade(@white, 5%);
    }
  }
  .tr{
    margin-bottom: 0.75rem;
  }
  .td.title{
    font-weight: 600;
  }
  .td.description{
    color: @grayMedium;
  }
  h3 {
    margin-bottom: 0.5em;
    .icon{
      height: 30px;
      width: 30px;
      position: relative;
      &.apple{
        top: -2px;
      }
    }
  }

  @media (min-width: @firstbreakpoint) {
    .os {
      padding: 3rem 0;
    }
    .table{
      display: table;
    }
    .tr{
      display: table-row;
      padding: 0;
    }
    .td{
      display: table-cell;
      padding-bottom: 0.75rem;
    }
    .td.title{
      width: 120px;
    }
  }
  @media (min-width: @thirdbreakpoint){
    div.app-width{
      padding: 3em 1em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 3em;
    }
    .os:nth-child(2){
      border-top: 0;
    }
    h3{
      border-bottom: 1px solid fade(@white, 5%);
      padding-bottom: 0.5rem;
    }
  }
</style>
