<template>
  <AppPage :fix-header="true">
    <TheHeroVideoSection />
    <TheIntroSection v-if="false" />
    <TheTestimonialSection v-if="false" />
    <TheExperienceSection v-if="false" />

    <div
      v-if="false"
      style="padding-top: 10vh; padding-bottom: 10vh; text-align: center; background: white;"
    >
      <h2 class="center">
        Find your perfect plan
      </h2>
      <p>
        With a wide range of plans, we have one that's right for you.
      </p>
      <stripe-pricing-table
        name="pricing"
        pricing-table-id="prctbl_1OW3HsIE6DThTlmtYLnMpkbi"
        publishable-key="pk_live_21OB8ZzdL40WUlERXDRqBg6G"
      />
    </div>
    <FaqSection v-if="false" />
    <div
      v-if="false"
      name="support"
      style="text-align: center"
    >
      <h3>Need help? Email me. support@chatwithvideos.com.</h3>
      <p v-if="false">
        Don't hesitate to reach out. We're here to make sure your YouChat experience is as smooth as possible.
      </p>
    </div>
  </AppPage>
</template>

<script lang="ts">
import AppPage from "@/components/organisms/AppPage.vue";
import TheHeroVideoSection from "@/components/organisms/TheHeroVideoSection.vue";
import TheIntroSection from "@/components/organisms/TheIntroSection.vue";
import TheExperienceSection from "@/components/organisms/TheExperienceSection.vue";
import TheSystemRequirementsSection from "@/components/organisms/TheSystemRequirementsSection.vue";
import TheTestimonialSection from "@/components/organisms/TheTestimonialSection.vue";
import TheMetadataSection from "@/components/organisms/TheMetadataSection.vue";
import TheExportSection from "@/components/organisms/TheExportSection.vue";
import pageHeadMixin from "@/components/mixins/pageHeadMixin.js";
import FaqSection from "@/components/organisms/FAQSection.vue";


export default {
  components: {
    TheExperienceSection,
    FaqSection,
    AppPage,
    TheHeroVideoSection,
    TheIntroSection,
    TheTestimonialSection,
  },
  mixins: [pageHeadMixin],
  watch: {
    '$store.state.user.user.email':{
      immediate: true,
      handler(){

      }
    }
  }
}
</script>

<style scoped lang="less">
  @import '../less/variables';


  video{
    width: 100%;
    outline: 0;
    text-align: center;
  }

  @media(min-width: @thirdbreakpoint){
    section.halfies{
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 6em 4em;
      gap: 2em;
      background: @white;
      > div{
        border-radius: 1em;
      }
    }
  }

</style>
