<template>
  <div class="metadata-section">

    <SectionTwoColumn class="app-width text-top">
      <div slot="graphic">
        <img src="/img/apple-homepod-storyboard.jpg" loading="lazy" class="fancy"/>
      </div>
      <div slot="text">
        <h4 class="color-4 uppercase">STEP 1</h4>
        <h1>
          <span  class="color-4">
            <IconSvg name="play" />
          </span>
          Start with an existing video
        </h1>
        <a name="import" />
        <p>
          Import a client sample video, competitors video or video you want to learn from, from YouTube, Vimeo, or a local file.
        </p>
        <img src="/img/import-options.png" style="max-width: 50%"/>
      </div>
    </SectionTwoColumn>

    <SectionTwoColumn class="app-width text-top alt">
      <div slot="graphic">
        <img src="/img/dollar-shave-club.jpg" class="fancy"/>
      </div>
      <div slot="text">
        <h4 class="color-4 uppercase">STEP 2</h4>
        <h1>
          <span class="color-4">
            <IconSvg name="film" />
          </span>
          Instantly view the storyboard
        </h1>
        <a name="highlight" />
        <p>
          Highlight key moments and elements:
        </p>

        <p >
          <strong>For video studios:</strong> Camera setups, lighting, production logistics, cost estimation, story, etc.
        </p>
        <p >
          <strong>For agencies:</strong> Engagement, branding, structure, aesthetics, hooks, virality, etc.
        </p>
        <p >
          <strong>For film & tv:</strong> Character, narrative, locations, set design, cinematography, etc.
        </p>
      </div>
    </SectionTwoColumn>


    <SectionTwoColumn class="app-width text-top alt">
      <div slot="graphic">
        <video src="https://blog.storyfolder.com/content/media/2023/06/AI-fields.mp4" poster="https://img.spacergif.org/v1/1280x720/0a/spacer.png" width="100%" loop="" autoplay="" muted="" playsinline="" preload="metadata" style="background: transparent url('https://blog.storyfolder.com/content/images/2023/06/media-thumbnail-ember234.jpg') 50% 50% / cover no-repeat;"></video>
      </div>
      <div slot="text">
        <h4 class="color-4 uppercase">STEP 3</h4>
        <h1>
          <span class="color-4">
            <IconSvg name="compose" />
          </span>
          Take notes on everything.
        </h1>
        <a name="take-notes" />


        <ul>
          <li>🎯 Strategy</li>
          <li>🏷️ Branding</li>
          <li>💬 Engagement</li>
          <li>🔄 Conversion</li>
          <li>📚 Storytelling</li>
          <li>🚀 Virality</li>
          <li>📣 Marketing & Advertising</li>
          <li>🎨 Aesthetics</li>
          <li>🎥 Cinematography</li>
          <li>🎬 Directing</li>
          <li>✨ VFX</li>
          <li>🔍 SEO</li>
          <li>💬 Messaging</li>
          <li>🤝 Collaboration</li>
          <li>💰 Monetization</li>
          <li>🎬 Production</li>
          <li>👀 Proofing & QA</li>
          <li>⚙️ Workflow</li>
          <li>🎞️ Editing</li>
          <li>🔊 Sound design</li>
          <li>🌍 Locations</li>
          <li>😃 and more</li>
        </ul>
      </div>
    </SectionTwoColumn>

    <SectionTwoColumn class="app-width" v-if="false">
      <div slot="graphic">
        <video src="https://blog.storyfolder.com/content/media/2023/06/AI-fields.mp4" poster="https://img.spacergif.org/v1/1280x720/0a/spacer.png" width="100%" loop="" autoplay="" muted="" playsinline="" preload="metadata" style="background: transparent url('https://blog.storyfolder.com/content/images/2023/06/media-thumbnail-ember234.jpg') 50% 50% / cover no-repeat;"></video>
      </div>
      <div slot="text">
        <h1>Unlock insights with <span class="tag ai">AI</span></h1>
        <p>
          Tap into the power of artificial intelligence to dig deeper into video analysis. Unearth critical elements to elevate your storytelling.
        </p>
      </div>
    </SectionTwoColumn>

    <SectionTwoColumn class="app-width alt">
      <div slot="graphic">
        <img src="/img/share-storyboard-link.jpg" class="fancy" style="width: 80%"/>
      </div>
      <div slot="text">
        <h4 class="color-1 uppercase">STEP 4</h4>
        <h1>
          <span  class="color-1">
            <IconSvg name="link" />
          </span>
          Share with your clients and team
        </h1>
        <a name="export-and-share" />
        <p>
          Share your storyboards, notes, pitches, and presentations with a click, safeguarded by a password.
        </p>
        <p>
          Export spreadsheets, images, clips, pro files, and more for your team and clients.
        </p>
        <Button class="wrap" size="small" to="/b/647269155473255d1351bfa7" style="margin-bottom: 0;">
          See an example
          <IconSvg name="link" />
        </Button>
        <p style="margin-top: 0.5em; color: gray;">
          <small>(It's a Game of Thrones scene breakdown 🗡)</small>
        </p>
      </div>
    </SectionTwoColumn>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import SectionTwoColumn from "@/components/molecules/SectionTwoColumn.vue";
  import IconSvg from "@/components/atoms/IconSvg.vue";
  import Button from "@/components/atoms/Button.vue";

  export default Vue.extend({
    components: {
      Button,
      IconSvg,
      SectionTwoColumn
    }
  });
</script>
<style scoped lang="less">
@import '../../less/variables';
@import '../../less/mixins';
  .metadata-section {
    background: url(/img/backgrounds/prism-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1px 0;
  }
  /deep/ section {
    margin: 10vh 0;
  }
  /deep/ .graphic{
    perspective: 600px;
    img{
      opacity: 0.8;
    }
  }
  p{
    color: #fff;
    &.gray{
      color: @grayLightMedium;
      strong{
        color: @white;
      }
    }
  }

  ul{
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    row-gap: 0.75em;
    padding: 0;
    list-style: none;
    font-size: 0.85em;
    li{
      color: @grayLightMedium;
      background: black;
      padding: 0.5em 1.1em;
      font-weight: 500;
      border-radius: 2em;
      margin: 0;
    }
  }
  .tag.ai{
    .gradient-background();
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 0 0.3em;
    border-radius: 0.2em;
    margin: 0.1em;
  }
</style>
