<template>
  <div class="app-width">
    <SectionTwoColumn class="text-top">
      <template slot="graphic">
        <img src="/img/apple-homepod-storyboard.jpg" loading="lazy" class="fancy"/>
      </template>
      <template slot="text">
        <h4 class="color-1 uppercase">VIDEO BLUEPRINT METHOD</h4>
        <h1>
          <span class="color-1">
            <IconSvg name="play"/>
          </span>
          Use videos as blueprints
        </h1>
        <p>
          Don't start from scratch when well-known, best-practices have been proven. Use existing videos to supercharge your content performance and productivity.
        </p>
      </template>
    </SectionTwoColumn>
    <SectionTwoColumn class="text-top">
      <template slot="graphic">
        <img src="/img/dollar-shave-club.jpg" class="fancy"/>
      </template>
      <template slot="text">
        <h4 class="color-1 uppercase">DISCOVER KEY INSIGHTS</h4>
        <h1>
          <span class="color-1">
            <IconSvg name="4-point-star"/>
          </span>
          Demystify high-performing content
        </h1>
        <p>
          Learn what makes "good" videos good. Use StoryFolder to analyze and understand the best practices of top-performing videos.
        </p>
      </template>
    </SectionTwoColumn>


    <SectionTwoColumn class="text-top">
      <template slot="graphic">
        <img src="/img/storyboard-preview.png" class="fancy"/>
      </template>
      <template slot="text">
        <h4 class="color-1 uppercase">IMPROVE CLIENT RELATIONS</h4>
        <h1>
          <span class="color-1">
            <IconSvg name="heart"/>
          </span>
          Build trust with clients
        </h1>
        <p>
          Improve the discovery process, clarify project needs, and demonstrate your industry expertise with beautiful, detail-rich breakdowns that clients love.
        </p>
      </template>
    </SectionTwoColumn>

    <SectionTwoColumn class="text-top">
      <template slot="graphic">
        <img src="/img/proofing.jpg" class="fancy"/>
      </template>
      <template slot="text">
        <h4 class="color-1 uppercase">VIDEO BUSINESS WORKFLOWS</h4>
        <h1>
          <span class="color-1" v-if="false">
            <IconSvg name="gear"/>
          </span>
          Improve quality, reduce costs
        </h1>
        <p>
          Estimate costs and logistics with more accuracy to complete projects in time and under budget.
        </p>
      </template>
    </SectionTwoColumn>


    <SectionTwoColumn class="text-top">
      <template slot="graphic">
        <img src="/img/samples/moana/shotlist.png" loading="lazy" class="fancy"/>
      </template>
      <template slot="text">
        <h1>
          <span class="color-1">
            <IconSvg name="refresh"/>
          </span>
          Export Spreadsheets, PDFs + more
        </h1>
        <p>
          Seamlessly integrate your data into any workflow with our diverse data exporting options including spreadsheets and PDFs.
        </p>
      </template>
    </SectionTwoColumn>


    <SectionTwoColumn class="text-top alt" v-if="false">
      <template slot="graphic">
        <img src="/img/samples/moana/images.png" loading="lazy" class="fancy"/>
      </template>
      <template slot="text">
        <h1>
          <span class="color-4">
            <IconSvg name="film"/>
          </span>
          Export Stills, Grabs + Clips
        </h1>
        <p>
          Capture the exact frames and clips you need for reference, sharing, and storyboarding. Export for creating mock-ups, re-edits, and placeholders directly from StoryFolder for a streamlined process.
        </p>
      </template>
    </SectionTwoColumn>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import SectionTwoColumn from "@/components/molecules/SectionTwoColumn.vue";
  import IconSvg from "@/components/atoms/IconSvg.vue";
  export default Vue.extend({
    components: {
      IconSvg,
      SectionTwoColumn
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  /deep/ section{
    margin: 8vh 0;
  }
  .app-width{
    max-width: 80vw;
  }
  .h1{
    text-align: center;
  }
  h3{
    margin: 0;
  }
</style>
