<template>
  <section class="two-column">
    <div class="content">
      <div class="graphic">
        <slot name="graphic"/>
      </div>
      <div class="text">
        <slot name="text"/>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
export default {
  props: {
    blackground: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style scoped lang="less">
  @import '../../less/variables';

  section.two-column{
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  .content{
    padding: 0;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-auto-flow: dense;
    gap: 1em;
  }
  .text{

  }
  .text-top .text{
    align-self: flex-start;
  }
  .graphic {
    text-align: center;
    img {
      width: 100%;
      &.perspective{
        box-shadow: 0px 2em 5em rgba(0,0,0,50%), 0px -10px 40px rgba(75, 80, 150, 50%);
        border-radius: 1em;
        transform-origin: 50% 50%;
        transform:  scale(0.9) perspective(200px) rotateX(2deg);
      }
      &.fancy{
        box-shadow: 0px 2em 5em rgba(0,0,0,50%), 0px -10px 40px rgba(75, 80, 150, 50%);
        border-radius: 1em;
      }
    }
  }

  /deep/ video{
    width: 100%;
    border-radius: 1em;
  }

  .two-column{
    margin-right: auto;
    margin-left: auto;
    max-width: 1800px;
  }

  h1 .icon{
    position: absolute;
    margin-left: -1.25em;
    display: none;
  }

  /deep/ video{
    border-radius: 1em;
  }

  @media (min-width: @secondbreakpoint) {
    .content{
      gap: 2em;
    }
  }

  @media(min-width: @thirdbreakpoint){
    .content{
      grid-template-columns: 1fr 1fr;
    }
    .two-column:nth-child(odd), .alt{
      .graphic{
        order: 2;
      }
      .text{
        order: 1;
      }
      img.perspective{
        transform-origin: 100% 100%;
        transform: scale(1) perspective(200px) rotateY(-1deg) rotateX(1deg);
      }
    }
    .graphic img.perspective{
      transform-origin: 0 100%;
      transform:  scale(1) perspective(200px) rotateY(1deg) rotateX(1deg);
    }
  }

  @media(min-width: @fourthbreakpoint){
    .content{
      gap: 4em;
      grid-template-columns: 55% 1fr;
    }
    .two-column:nth-child(odd), .alt{
      .content{
        grid-template-columns: 1fr 55%;
      }
    }
  }
</style>
