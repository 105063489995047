<template>
  <div
    class="section"
    name="faqs"
  >
    <h1>
      Frequently Asked Questions
    </h1>
    <p>
      If you have any other questions, reach out to us.
    </p>
    <div class="faq-grid">
      <div
        v-for="(faq, index) in faqs"
        :key="index"
        class="faq-item"
      >
        <h3 class="faq-question">
          {{ faq.question }}
        </h3>
        <p class="faq-answer">
          {{ faq.answer }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          "question": "How many videos can I chat with daily on YouChat?",
          "answer": "YouChat Basic: Chat with up to 50 videos per day. YouChat Premium: Engage with up to 200 videos daily. YouChat Vision: Interact with 10 videos per day, with comprehensive visual analysis included."
        },
        {
          "question": "Can I chat with videos that don’t have transcripts?",
          "answer": "Yes, YouChat is designed to work seamlessly with all types of YouTube videos, including those without transcripts."
        },
        {
          "question": "Does YouChat include interactions with the comment section of videos?",
          "answer": "Currently, our service focuses on the video content itself. However, we are exploring the possibility of incorporating comment section interactions in the future."
        },
        {
          "question": "Are my chat conversations saved for later reference?",
          "answer": "Absolutely! We store all your chat records, allowing you to revisit and continue conversations with videos at any time."
        },
        {
          "question": "Is YouChat frequently updated and maintained?",
          "answer": "Yes, we are committed to continuous improvement. Your feedback is crucial, and we strive to implement new features and refinements regularly."
        },
        {
          "question": "Which browsers are compatible with YouChat?",
          "answer": "YouChat is available as a web version compatible with all major browsers. We also offer an extension specifically for Chromium-based browsers."
        },
        {
          "question": "Can I use YouChat in different languages?",
          "answer": "Definitely! YouChat supports multiple languages, allowing you to chat in your preferred language for an enhanced experience."
        },
        {
          "question": "How can I give feedback or make suggestions about YouChat?",
          "answer": "We value your input! Please submit your feedback or suggestions through our dedicated feedback form at YouChat Feedback."
        },
        {
          "question": "What technology powers the AI conversations in YouChat?",
          "answer": "YouChat utilizes advanced AI, including technology similar to ChatGPT, to analyze video content and generate contextually relevant and engaging discussions."
        },
        {
          "question": "What are the key features included in each YouChat plan?",
          "answer": "YouChat Basic ($4.99/month): Up to 50 videos and 50 messages per video daily, for videos up to 30 minutes. YouChat Premium ($12.99/month): Up to 200 videos and 200 messages per video daily, for videos up to 1 hour. YouChat Vision ($19.99/month): Comprehensive visual analysis, up to 10 videos and 200 messages per video daily."
        }
      ]

    }
  }
}
</script>

<style scoped>
.section {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 40px;
  margin: 0 auto;
}


.faq-question {
  font-weight: 600;
  margin: 0 0 10px 0;
  color: #333;
  font-size: 1.2rem;
}

.faq-answer {
  line-height: 1.6;
  color: #666;
}
</style>
