<template>
  <section>
    <div>
      <TheStoryFolderLogo append="Vision" />
      <br>
      <br>
      <h1>See Beyond the Transcript</h1>
      <h2>YouChat Vision understands the visual details.</h2>
      <Button
        v-if="false"
        class="special rounded"
        label="Try Free Now"
        size="large"
        :has-chevron="true"
        :to="{name: 'signup'}"
      />
    </div>
    <div class="app-width">
      <div
        v-if="false"
        style="padding: 2em; border:1px solid white;"
      >
        <p>
          this will be an screenshot of a video with a bunch of stuff highlighted, demonstrating the visual awareness.
        </p>
      </div>
      <br><br>
      <div class="featurettes">
        <Featurette
          icon="eye"
          title="On-Screen Text"
          description="Slides, captions, and other on-screen text are automatically extracted."
        />
        <Featurette
          icon="graph"
          title="Graphs & Charts"
          description="Visual data and infographics are seamlessly integrated into your chats."
        />
        <Featurette
          icon="film"
          title="Video Production"
          description="Scene, setting, storyboards, character, and other production details are at your fingertips."
        />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Button from "@/components/atoms/Button.vue";
  import TheStoryFolderLogo from "@/components/molecules/TheStoryFolderLogo.vue";
  import Featurette from "@/components/molecules/Featurette.vue";

  export default Vue.extend({
    components: {
      Featurette,
      TheStoryFolderLogo,
      Button
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section {
    background-image: url(/img/backgrounds/prism-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    padding: 3rem 0;
    color: @white;
  }
  .logo{
    font-family: helvetica, arial, sans-serif;
    font-weight: 600;
    span{
      font-size: 0.75em;
      text-transform: uppercase;
      font-weight: 300;
      opacity: 0.5;
    }
  }
  h2{
    margin:0;
  }
  /deep/ p{
    color: fade(@white, 75%);
    font-size: 90%;
    margin-top: 0;
  }

  .app-width{
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  h1{
    margin: 1.25em 0 0.5em 0;
    text-align: center;
  }
  .featurettes{
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    margin-top: 1em;
    margin-bottom: 3em;
    gap: 1em;
  }
  .featurette{
    margin-bottom: 1.5em;
    text-align: left;
  }
  h3{
    margin-bottom: 1em;
    text-align: center;
  }

  .coverflow{
    text-align: center;
    margin: 2em 0;
    img{
      width: 80%;
    }
  }
  @media(min-width: @firstbreakpoint){
    .coverflow img{
      width: 80%;
    }
  }
  @media(min-width: @thirdbreakpoint){
    .featurette{
      font-size: 1.5rem;
    }
    .featurettes{
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 7em;
    }
    h1 span, h3 span{
      display: block;
    }
  }
</style>
