<template>
  <div class="section">
    <h1 class="center">
      Users Love YouChat <IconSvg
        name="heart"
        style="color:red"
      />
    </h1>
    <br>
    <div
      ref="scroller"
      class="testimonial-auto-scroller"
    >
      <Testimonial
        v-for="(testimonial, index) in testimonials"
        :key="index"
        :headline="testimonial.headline"
        :review="testimonial.review"
        :name="testimonial.name"
      />
    </div>
  </div>
</template>

<script>
// import TestimonialElement from './TestimonialElement.vue';

import IconSvg from "@/components/atoms/IconSvg.vue";
import Testimonial from "@/components/molecules/Testimonial.vue";

export default {
  components: {
    IconSvg,
    Testimonial
  },
  data() {
    return {
      scrollInterval: null
    };
  },
  computed: {
    testimonials(){
      return this.$store.state.testimonials.items
    },
    testimonialsRandomized(){
      return [...this.testimonials].sort(() => Math.random() - 0.5);
    }
  },
  mounted() {
    this.startAutoScroll();
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.scrollInterval);
  },
  methods: {
    startAutoScroll() {
      const scroller = this.$refs.scroller;
      let step = 1; // Number of pixels to scroll at each interval

      this.scrollInterval = setInterval(() => {
        if (scroller.scrollWidth <= scroller.clientWidth + scroller.scrollLeft) {
          scroller.scrollLeft -= scroller.clientWidth; // Resets to just before the first element if at the end
        } else {
          scroller.scrollLeft += step;
        }
      }, 20); // Adjust interval speed as needed
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../less/variables';
.section{
  background: #eee;
  padding-bottom: 10vh;
}
h2{
  text-align: center;
}
.testimonial-auto-scroller {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  gap: 2rem;
  align-items: center;
}

.testimonial-auto-scroller::after {
  content: "";
  flex: 0 0 300px;
}
</style>
